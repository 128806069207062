import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/Layout";
import plantsRed from "../img/plants-red-3.png";
import plantsYellow from "../img/plant-yellow-1.png";
import aboutMain from "../img/about-main.png";
import aboutMission from "../img/about-mission.svg";
import klaudia from "../img/about-klaudia.svg";
import wiktoria from "../img/about-wiktoria.svg";
import website from "../img/social-website.svg";
import linkedin from "../img/social-linkedin.svg";
import twitter from "../img/social-twitter.svg";
import googlePlay from "../img/play_store.svg";
import appStore from "../img/app_store.svg";

// eslint-disable-next-line
export const AboutPageTemplate = () => {

  return (
    <div>
      <section className="section dark-background-section">
          <div className="columns">
          <div className="column is-10 is-offset-1">
          <div className="content">
          <div className="columns">
            <div className="column is-6 vertical-center">
              <div className="content about-text">
                <h1 className="title about-main-title">
                    About<br />One a Day
                </h1>
                <p className="about-text" style={{fontSize: "1.5rem", fontWeight: "500"}}>
                    One a Day is a passion project born from personal experiences and a genuine wish to help others
                </p>
                <div className="row" style={{marginTop: "5vh", zIndex: "20"}}>
                <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US"><img style={{width: "180px", marginRight: "1rem"}} src={googlePlay} /></a> 
                <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197"><img style={{width: "180px"}} src={appStore} /></a> 
              </div>
              </div>
            </div>
            <div className="column is-6 vertical-center" >
            <img className="about-image" src={aboutMain} />
            </div>
            </div>
          </div>
            </div>
          </div>
      </section>
      <section className="section">
      <div className="columns">
          <div className="column is-10 is-offset-1">
          <div className="content">
          <div className="columns">
            <div className="column is-6 vertical-center">
              <div className="content">
                <h1 className="title about-title">
                    Our Mission
                </h1>
                <p style={{fontSize: "1.5rem", fontWeight: "500"}}>
                We know that getting help can be difficult. We want to give people support and a safe space on their path to wellbeing. 
                </p>
              </div>
            </div>
            <div className="column is-6 about-text has-text-centered" >
            <img className="about-image" src={aboutMission} />
            </div>
            </div>
            </div>
          </div>
          </div>
      </section>
      <section className="section dark-background-section">
          <div className="columns">
          <div className="column is-10 is-offset-1">
          <div className="content"  style={{position: "relative"}}>
          <div style={{position: "absolute", left: "-9rem", top: "-6rem"}}>
                <img src={plantsYellow}  />
                </div>
            <h1 className="title about-title">Who we are</h1>
                  <div className="columns">
            <div className="column is-6">
              <div className="content has-text-centered">
                <img src={klaudia} />
                <h1 className="about-title">
                    Klaudia Romek
                </h1>
                <p style={{fontSize: "1.5rem", fontWeight: "500"}}>
                    Founder and software developer
                </p>
                <div className="content has-text-centered" style={{fontWeight: 500, textAlign: "left" }}>
                <a title="website" href="https://klaudiabronowicka.com">
                  <img
                    src={website}
                    alt="Klaudia's website"
                    style={{ width: "2em", height: "2em", margin: "0em 1em 0em 0em" }}
                  />
                </a>
                <a title="linkedin" href="https://www.linkedin.com/in/kbronowicka">
                  <img
                    className="fas fa-lg"
                    src={linkedin}
                    alt="Klaudia's LinkedIn"
                    style={{ width: "2em", height: "2em", margin: "0em 1em 0em 0em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/K_Romek">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Klaudia's Twitter"
                    style={{ width: "2em", height: "2em" }}
                  />
                </a>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="content has-text-centered" style={{position: "relative"}}>
              <img src={wiktoria} />
                <h1 className="about-title">
                    Wiktoria Romek
                </h1>
                <p style={{fontSize: "1.5rem", fontWeight: "500"}}>
                    UX designer
                </p>
                <div className="content has-text-centered" style={{fontWeight: 500, textAlign: "left" }}>
                <a title="website" href="https://wiktoriaromek.com">
                  <img
                    src={website}
                    alt="Wiktoria's website"
                    style={{ width: "2em", height: "2em", margin: "0em 1em 0em 0em" }}
                  />
                </a>
                <a title="linkedin" href="https://www.linkedin.com/in/wiktoria-romek-9421771b3">
                  <img
                    className="fas fa-lg"
                    src={linkedin}
                    alt="Wiktoria's LinkedIn"
                    style={{ width: "2em", height: "2em" }}
                  />
                </a>
                </div>
                <div style={{position: "absolute", right: "-8rem", bottom: "-5rem"}}>
                <img src={plantsRed} className="is-hidden-mobile" />
                </div>
                
              </div>
            </div>
            </div>
            </div>
        </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
};

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <AboutPageTemplate
      />
    </Layout>
  );
};

export default AboutPage;